import React from 'react';
import {fetchUtils, Admin, Resource, CustomRoutes, Authenticated} from 'react-admin';
import { Route } from 'react-router-dom';
import { PageList, PageEdit, PageCreate } from './components/Pages/Pages';
import { CTAList, CTAEdit, CTACreate } from './components/CallsToAction/CTA';
import { ProjectList, ProjectEdit } from './components/Projects/Projects';
import { UserEdit, UserList, UserCreate, UserShow } from "./components/Users/Users";
import { MenusList, MenusEdit, MenusCreate } from "./components/Menus/Menus";
import { createTheme } from '@mui/material/styles';
import ProjectIcon from '@mui/icons-material/Assignment';
import simpleRestProvider from './utilities/dataProvider';
import Dashboard from './components/Dashboard/Dashboard';
import HelpPage from './components/HelpPage/HelpPage';
import PageContainer from './components/UI/PageContainer';
import PageIcon from '@mui/icons-material/Description';
import MenuItemsIcon from '@mui/icons-material/List';
import authProvider from "./utilities/authProvider";
import CustomLayout from "./components/UI/Menu/Menu";
import { PhasesEdit } from './components/Phases/Phases';
import ComponentKitWrapper from "./components/ComponentKit/ComponentKitWrapper";
import {storybookData} from "./utilities/storybookData";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('idToken');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

//const campaignApiUrl = localStorage.getItem('RaStore.campaign.apiUrl');
//const dataProvider = simpleRestProvider(localStorage.getItem(campaignApiUrl), httpClient);
const dataProvider = simpleRestProvider(process.env.REACT_APP_API_URL, httpClient);
const theme = createTheme({
    palette: {
        primary: {
            light: '#D05CE3',
            main: '#9C27B0',
            dark: '#6A0080',
            contrastText: '#fff',
        },
        secondary: {
            light: '#A6E14E',
            main: '#74c91e',
            dark: '#4FA910',
            contrastText: '#222',
        },
    },
    typography: {
        body2: {
            fontSize: '.9rem'
        }
    },
});

const App = () => {

    const ComponentKitRoutes = storybookData.map((story, index) => {
        return <Route key={index} path={`/vorlagen/${encodeURIComponent(story)}`} element={<ComponentKitWrapper sbComponentName={encodeURIComponent(story)}/>}/>
    })

    return (
        <Admin
            theme={theme}
            dashboard={PhasesEdit}
            disableTelemetry
            requireAuth
            authProvider={authProvider}
            dataProvider={dataProvider}
            layout={CustomLayout}
        >

            <CustomRoutes>
                <Route path="/dashboard" element={<Authenticated><Dashboard/></Authenticated>}/>
                <Route path="/phases" element={<Authenticated><PhasesEdit/></Authenticated>} />
                <Route path="/help" element={<Authenticated><PageContainer><HelpPage/></PageContainer></Authenticated>} />
                {ComponentKitRoutes}
            </CustomRoutes>
            <Resource options={{label: "CTA"}} name="cta" list={CTAList} edit={CTAEdit} create={CTACreate}
                      icon={PageIcon}/>
            <Resource options={{label: "Navigation"}} name="menus" list={MenusList} edit={MenusEdit}
                      create={MenusCreate} icon={MenuItemsIcon}/>
            <Resource options={{label: "Projekte"}} name="admin/projects" list={ProjectList} edit={ProjectEdit}
                      icon={ProjectIcon}/>
            <Resource options={{label: "Seiten"}} name="pages" list={PageList} edit={PageEdit} create={PageCreate}
                      icon={PageIcon}/>
            <Resource options={{label: "Nutzer"}} name="users" list={UserList} edit={UserEdit} create={UserCreate}
                      show={UserShow} icon={ProjectIcon}/>
        </Admin>
    );
}

export default App;
