import React, {useState} from 'react';
import {Layout, MenuItemLink, Menu} from 'react-admin';
import SubMenu from './SubMenu'
import {
    Announcement,
    Assignment,
    Dashboard,
    Description,
    ExpandLess,
    ExpandMore,
    Help,
    List as ListIcon,
    People,
    SettingsApplications,
    StarBorder,
} from "@mui/icons-material";
import {ListItemButton, ListItemIcon, ListItemText, Collapse, Divider, List} from "@mui/material";
import {storybookData} from "../../../utilities/storybookData";

const menuItems = [
    {name: 'phases', text: 'Wettbewerb', icon: <SettingsApplications/>},
    {name: 'cta', text: 'Calls-To-Action', icon: <Announcement/>},
    {name: 'menus', text: 'Navigation', icon: <ListIcon/>},
    {name: 'admin/projects', text: 'Projekte', icon: <Assignment/>},
    {name: 'users', text: 'Nutzer', icon: <People/>},
    {name: 'pages', text: 'Seiten', icon: <Description/>},
    {name: 'help', text: 'Hilfe', icon: <Help/>}
];

const CustomMenu = ({props, onMenuClick, open, logout}) => {

    const [menuComponentKitOpen, setMenuComponentKitOpen] = useState(false)

    const handleToggle = () => {
        setMenuComponentKitOpen(!menuComponentKitOpen);
    }

    /**
     * Converts the array of strings into a one-to-many connection object.
     * Each string in the array follows the pattern "parent--child".
     * The function creates an object where each unique "parent" is a key,
     * and its corresponding "child" values are stored in arrays as values.
     *
     * @param {string[]} inputArray - The array of strings to be converted.
     * @returns {Object} - The one-to-many connection object.
     */
    function convertToOneToMany(inputArray) {
        const oneToManyConnection = {};

        for (const item of inputArray) {
            const [parentPart, childPart] = item.split('--');

            if (!oneToManyConnection[parentPart]) {
                oneToManyConnection[parentPart] = [childPart];
            } else {
                oneToManyConnection[parentPart].push(childPart);
            }
        }
        return oneToManyConnection;
    }

    const oneToManyConnection = convertToOneToMany(storybookData);
    const NestedMenuItems = Object.entries(oneToManyConnection).map(([parentName, childNames], index) => (
        <SubMenu
            key={index}
            sidebarIsOpen={open}
            name={parentName.replace(/-/g, ' ').split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
            icon={<ExpandLess/>}>
            {childNames.map((childName, childIndex) => (
                <MenuItemLink
                    sx={{pl: 3}}
                    key={childIndex}
                    to={`vorlagen/${parentName}--${childName}`}
                    primaryText={childName.replace(/-/g, ' ').split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                    leftIcon={<SettingsApplications/>}
                    onClick={onMenuClick}/>
            ))}
        </SubMenu>
    ))

    return (
        <Menu {...props}>
            <MenuItemLink
                key='dashboard'
                to={'/dashboard'}
                primaryText='Übersicht'
                leftIcon={<Dashboard/>}
                onClick={onMenuClick}
            />
            <div>
                <ListItemButton onClick={handleToggle}>
                    <ListItemIcon style={{paddingRight: '1.2em', minWidth: 0}}>
                        <StarBorder/>
                    </ListItemIcon>
                    <ListItemText primary="ComponentKit"/>
                    {menuComponentKitOpen ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={menuComponentKitOpen} timeout="auto" unmountOnExit
                          style={{background: '#e9e9e9', boxShadow: 'inset 0 2px 3px 0 rgba(0,0,0,.25)'}}>
                    <Divider/>
                    <List component="div" disablePadding>
                        {NestedMenuItems}
                    </List>
                </Collapse>
            </div>
            {menuItems.map(item => (
                <MenuItemLink
                    key={item.name}
                    to={`/${item.name}`}
                    primaryText={item.text}
                    leftIcon={item.icon}
                    onClick={onMenuClick}
                />
            ))}
        </Menu>
    )
};

const CustomLayout = props => <Layout {...props} menu={CustomMenu}
                                      sx={{'& .RaLayout-content': {paddingLeft: '16px'}}}/>;

export default CustomLayout;
